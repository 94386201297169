<template>
  <div class="thumbnails__item photo">
    <img :src="photo.img_preview" :alt="name + $t('shared.alts.photo')" />
  </div>
</template>

<script>
import { WATERMARK } from '../../../mixins/utils';

export default {
  mixins: [WATERMARK],
  props: {
    photo: Object,
    name: String,
  },
  mounted() {
    /**
     * запуск ватермарки
     */
    this.getWatermark({
      targetObj: this.photo,
      parentImg: this.photo.img_large,
      wmImg: this.config.watermarkSmall,
      modifierX: 255,
      modifierY: 255,
      variable: 'wmLarge',
    });
  },
};
</script>
