<template>
  <div class="users-preview" :class="{ 'with-favs': withFavs && sex.isMan }">
    <div class="user-preview">
      <div v-if="withDelete" class="user-preview--delete d-flexhidden-xs">
        <button class="user-preview--delete__btn" type="button" @click.prevent.stop="deleteEvent">
          <span class="rbi rbi-close" />
        </button>
      </div>
      <div v-if="withFavs && sex.isMan" class="user-preview__favs">
        <button
          v-if="!invite.removed"
          class="user-preview__favs-btn add"
          type="button"
          :class="{ active: invite.accepted }"
          @click.prevent.stop="acceptInvite"
        >
          <span class="rbi rbi rbi-heart" />
        </button>
        <button
          v-if="!invite.accepted"
          class="user-preview__favs-btn del"
          type="button"
          @click.prevent.stop="rejectInvite"
        >
          <span class="rbi rbi-close" />
        </button>
      </div>
      <template v-if="sex.isWoman">
        <router-link class="wrap-user" :to="{ name: 'profile', params: { id: user.user_id } }">
          <div class="user-preview-thumb">
            <div class="user-preview-thumb-wrap">
              <div v-if="user.new" class="user-status-wrap-new">new</div>
              <div class="user-avatar">
                <img v-lazy="user.avatar.img_large" alt="" />
              </div>
              <div class="user-action">
                <ul class="action-items">
                  <li @click.prevent.stop="active = false">
                    <button
                      class="wink"
                      :class="{ disabled: disabled.wink }"
                      :title="$t('shared.titles.sendWink')"
                      @click.prevent.stop="previewSendWink(preview(false), user.user_id)"
                    >
                      <span class="checked-wrap"><i class="fa fa-check" /></span>
                    </button>
                  </li>
                  <li @click.prevent.stop="active = false">
                    <button
                      class="bookmark"
                      :class="{ disabled: disabled.bookmarks }"
                      :title="$t('shared.titles.addBookmark')"
                      @click.prevent.stop="previewAddBookmark(preview(false), user.user_id)"
                    >
                      <span class="checked-wrap"><i class="fa fa-check" /></span>
                    </button>
                  </li>
                  <li @click.prevent.stop="active = false">
                    <button
                      class="chat"
                      :class="{ disabled: disabled.chat }"
                      :title="$t('shared.titles.startChat')"
                      @click.prevent.stop="previewStartChat(preview(false), user.user_id)"
                    />
                  </li>
                  <li @click.prevent.stop="active = false">
                    <button
                      class="mail"
                      :class="{ disabled: disabled.mail }"
                      :title="$t('shared.titles.sendMail')"
                      @click.prevent.stop="previewSendMail(preview(false), user)"
                    />
                  </li>
                  <li @click.prevent.stop="active = false">
                    <button
                      class="gift"
                      :class="{ disabled: disabled.gift }"
                      :title="$t('shared.titles.sendGift')"
                      @click.prevent.stop="
                        sex.isMan
                          ? previewSendGift(preview(false), user.user_id)
                          : previewSendVirtualGift(preview(false), user.user_id)
                      "
                    />
                  </li>
                  <!-- <li @click.prevent.stop="active = false">
                       <button class="favorite"
                               :class="{disabled:disabled.favs.status || disabled.favs.ignored ||disabled.favs.isFav}"
                               @click.prevent.stop="previewAddToFavs(preview(false), user.user_id)"
                               :title="$t('shared.titles.addFavorites')">
                               <span class="checked-wrap" v-if="!dis.favs.ignored || dis.favs.tmp"><i class="fa fa-check"></i></span></button>
                     </li>-->
                </ul>
              </div>
              <div class="user-status">
                <div v-if="userWebcam(user.camera).status" class="user-status-wrap webcam-wrap">
                  <span class="webcam rbi-webcam" :class="{ hd: userWebcam(user.camera).hd }" />
                </div>
                <div v-if="user.online" class="user-status-wrap online-wrap">
                  <div class="online">
                    <span class="indication" /><span>{{ $t('profile.online') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="user-preview-name" @click.prevent.stop="preview(true)">
            <span>ID: {{ user.user_id }}</span>
            <p
              :title="
                sex.isWoman && user.name.length > 20
                  ? user.birthday !== null
                    ? user.name + ', ' + getUserAge(user.birthday)
                    : user.name
                  : ''
              "
            >
              {{ user.name }}
              <template v-if="user.birthday !== null"> , {{ getUserAge(user.birthday) }} </template>
            </p>
          </div>
        </router-link>
      </template>
      <div v-else class="wrap-user" @click.prevent.stop="preview(true)">
        <div class="user-preview-thumb">
          <div class="user-preview-thumb-wrap">
            <div v-if="user.new" class="user-status-wrap-new">new</div>
            <div class="user-avatar">
              <img v-lazy="user.avatar.img_large" alt="Photo" />
            </div>
            <div class="user-action">
              <ul class="action-items">
                <li @click.prevent.stop="active = false">
                  <button
                    class="wink"
                    :class="{ disabled: disabled.wink }"
                    :title="$t('shared.titles.sendWink')"
                    @click.prevent.stop="previewSendWink(preview(false), user.user_id)"
                  >
                    <span class="checked-wrap"><i class="fa fa-check" /></span>
                  </button>
                </li>
                <li @click.prevent.stop="active = false">
                  <button
                    class="bookmark"
                    :class="{ disabled: disabled.bookmarks }"
                    :title="$t('shared.titles.addBookmark')"
                    @click.prevent.stop="previewAddBookmark(preview(false), user.user_id)"
                  >
                    <span class="checked-wrap"><i class="fa fa-check" /></span>
                  </button>
                </li>
                <li @click.prevent.stop="active = false">
                  <button
                    class="chat"
                    :class="{ disabled: disabled.chat }"
                    :title="$t('shared.titles.startChat')"
                    @click.prevent.stop="previewStartChat(preview(false), user.user_id)"
                  />
                </li>
                <li @click.prevent.stop="active = false">
                  <button
                    class="mail"
                    :class="{ disabled: disabled.mail }"
                    :title="$t('shared.titles.sendMail')"
                    @click.prevent.stop="previewSendMail(preview(false), user)"
                  />
                </li>
                <li @click.prevent.stop="active = false">
                  <button
                    class="gift"
                    :class="{ disabled: disabled.gift }"
                    :title="$t('shared.titles.sendGift')"
                    @click.prevent.stop="
                      sex.isMan
                        ? previewSendGift(preview(false), user.user_id)
                        : previewSendVirtualGift(preview(false), user.user_id)
                    "
                  />
                </li>
                <!-- <li @click.prevent.stop="active = false">
                     <button class="favorite"
                             :class="{disabled:disabled.favs.status || disabled.favs.ignored ||disabled.favs.isFav}"
                             @click.prevent.stop="previewAddToFavs(preview(false), user.user_id)"
                             :title="$t('shared.titles.addFavorites')">
                             <span class="checked-wrap" v-if="!dis.favs.ignored || dis.favs.tmp"><i class="fa fa-check"></i></span></button>
                   </li>-->
              </ul>
            </div>
            <div class="user-status">
              <div v-if="userWebcam(user.camera).status" class="user-status-wrap webcam-wrap">
                <span class="webcam rbi-webcam" :class="{ hd: userWebcam(user.camera).hd }" />
              </div>
              <div v-if="user.online" class="user-status-wrap online-wrap">
                <div class="online">
                  <span class="indication" /><span>{{ $t('profile.online') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="user-preview-name" @click.prevent.stop="preview(true)">
          <span>ID: {{ user.user_id }}</span>
          <p
            :title="
              sex.isWoman && user.name.length > 20
                ? user.birthday !== null
                  ? user.name + ', ' + getUserAge(user.birthday)
                  : user.name
                : ''
            "
          >
            {{ user.name }}
            <template v-if="user.birthday !== null"> , {{ getUserAge(user.birthday) }} </template>
          </p>
        </div>
      </div>

      <div v-click-away="closeMenu" class="action">
        <user-actions :user="user" :class="{ active: active }" :disabled-props="disabled" />
        <button class="more" @click.prevent.stop="toggleDropdown">
          <span class="rbi rbi-more" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { PHOTO_SRC, AGE, WEBCAM } from '../../mixins/utils';
import { PREVIEW_MODAL } from '../../mixins/formsActions';
import {
  USER_ACTIONS,
  ACTIONS_DATA,
  CHECK_PRIVILEGES,
  COMPARE_DATA,
} from '../../mixins/userActions';
import userActions from './parts/UserActions';

export default {
  name: 'UserPreview',
  components: {
    userActions,
  },
  mixins: [
    PREVIEW_MODAL,
    PHOTO_SRC,
    AGE,
    USER_ACTIONS,
    WEBCAM,
    ACTIONS_DATA,
    CHECK_PRIVILEGES,
    COMPARE_DATA,
  ],
  props: {
    user: Object,
    disabledProps: Object,
    column: Boolean,
    withDelete: Boolean,
    withFavs: Boolean,
  },
  data() {
    return {
      invite: {
        accepted: false,
        removed: false,
      },
      active: false,
      name: '',
    };
  },
  computed: {
    actionsUserId() {
      return this.user.user_id;
    },
    token() {
      return this.$store.getters.user.accessToken;
    },
    sex() {
      return this.$store.getters.sex;
    },
    disabledIsFav() {
      return this.disabled.favs.isFav;
    },
  },
  watch: {
    disabledIsFav(v) {
      if (this.withFavs) this.invite.accepted = v;
    },
    // user(v){
    //   if(this.user.name.length > 20) this.user.name = v.name.slice(0, 12).trim() + '...';
    // }
  },
  mounted() {
    this.canChat(this.user.online);
    // if(this.user.name.length > 20) this.user.name = this.user.name.slice(0, 12).trim() + '...'
  },
  methods: {
    /**
     * закрыть дропдаун с действиями
     */
    closeMenu() {
      this.active = false;
    },
    alertM() {
      alert(1);
    },
    /**
     * првоерка привилегий при клик на действие и дальнейшая из обработка
     *
     * @param bool {boolean} условие
     * @return {boolean}
     */
    preview(bool) {
      if (this.sex.isMan || !window.localStorage['access-token']) {
        if (bool) {
          // show usual preview
          this.openPreviewModal(this.user.user_id, bool);
        } else if (this.token) {
          // if authorized and bool == false
          return true;
        } else {
          // if non authorized and bool == false
          this.openPreviewModal(this.user.user_id, bool);
        }
        return false;
      }
      if (this.sex.isWoman) {
        if (bool) {
          this.$router.push({ name: 'profile', params: { id: this.user.user_id } });
          return false;
        }
        return true;
      }
    },
    /**
     * послать в родительский компонент событие об удалении пользователя
     */
    deleteEvent() {
      this.$emit('deleting', {
        user_id: this.user.user_id,
      });
      this.$el.outerHTML = ''; // remove user from HTML markup
    },
    /**
     * принять инвайт добавления в фавориты
     */
    acceptInvite() {
      if (this.sex.isMan) {
        this.$http
          .post('v1/favorite/add', {
            access_token: window.localStorage['access-token'],
            favorite_user_id: this.user.user_id,
          })
          .then((response) => {
            if (response.body.status) {
              this.$emit('accept');
              this.invite.accepted = true;
              this.$el.outerHTML = '';
            }
          });
      }
    },
    /**
     * отклонить инвайт добавления в фавориты
     */
    rejectInvite() {
      if (this.sex.isMan) {
        this.$http
          .post('v1/favorite/delete', {
            access_token: window.localStorage['access-token'],
            favorite_user_id: this.user.user_id,
          })
          .then((response) => {
            if (response.body.status) {
              this.$emit('reject');
              this.invite.removed = true;
              this.$el.outerHTML = '';
            }
          });
      }
    },
    /**
     * переключить отображение дропдауна
     */
    toggleDropdown() {
      this.active = !this.active;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/scss/vars';

@keyframes example {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
  }
}

a {
  color: #3e3f42;
  text-decoration: none;
}

.action {
  display: none;
}

.news-box--item__inner {
  .user-preview {
    &-thumb {
      &-wrap {
        .user-action {
          right: -16.5px;
          ul {
            button {
              height: 33px;
              width: 33px;
            }
          }
        }
        .user-status {
          bottom: 20px;
        }
      }
    }
    &-name {
      margin-top: 15px;
      margin-left: 0;

      span {
        color: $black;
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.8px;
      }

      p {
        color: $black;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.4;
        letter-spacing: 0.9px;
        margin-top: 0;
      }
    }
    &:hover {
      .user-action {
        right: 15px;
      }
    }
  }
}

.users-preview {
  &.not-viewed {
    .user-preview-thumb-wrap {
      position: relative;
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background: #e21919;
        opacity: 0.3;
        animation: example 3s infinite ease-in-out;
        z-index: 1;
      }
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background: #333;
        opacity: 0.3;
        animation: example 3s infinite ease-in-out;
        animation-delay: 1.5s;
        z-index: 1;
      }
    }
  }
  &.with-favs {
    .action {
      display: none !important;
    }
  }
}
.users-block {
  &-flex {
    .users-preview {
      flex: 0 0 25%;
      width: 25%;
      margin-bottom: 20px;
    }
    .user-preview {
      &-thumb {
        &-wrap {
          .user-action {
            right: -16.5px;
            ul {
              button {
                height: 33px;
                width: 33px;
              }
            }
          }
          .user-status {
            bottom: 20px;
          }
        }
      }
      &-name {
        margin-top: 15px;
        margin-left: 0;

        span {
          color: $black;
          font-size: 12px;
          font-weight: 400;
          text-transform: uppercase;
          letter-spacing: 0.8px;
        }

        p {
          color: $black;
          font-size: 18px;
          font-weight: 400;
          line-height: 1.4;
          letter-spacing: 0.9px;
          margin-top: 0;
        }
      }
      &:hover {
        .user-action {
          right: 15px;
        }
      }
    }
  }

  .user-preview {
    &-thumb {
      &-wrap {
        .user-action {
          right: -16.5px;
          ul {
            button {
              height: 33px;
              width: 33px;
            }
          }
        }
      }
    }
    &-name {
      margin-top: 15px;
      margin-left: 0;

      span {
        color: $black;
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.8px;
      }

      p {
        color: $black;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.4;
        letter-spacing: 0.9px;
        margin-top: 0;
      }
    }
    &:hover {
      .user-action {
        right: 15px;
      }
    }
  }
}

.user-preview-block {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.user-preview {
  width: 100%;
  cursor: pointer;
  overflow: hidden;

  &-thumb {
    position: relative;

    &:before {
      content: '';
      height: 4px;
      width: 25%;
      transition: 0.3s;
      background: red;
      display: block;
      position: absolute;
      left: 0;
      bottom: -4px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      background: rgba($black, 0.6);
      opacity: 0;
      transition: 0.3s;
    }

    &-wrap {
      overflow: hidden;
      padding-bottom: 116.478%;
      position: relative;
      width: 100%;

      .user-avatar {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;

        &.avatar {
          img {
            display: none;
          }
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .user-action {
        position: absolute;
        right: -25px;
        top: 0;
        z-index: 2;
        height: 100%;
        opacity: 0.5;
        transition: 0.3s;
        transition-delay: 0.1s;

        ul {
          list-style: none;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-around;
          margin: 0;
          padding: 10px 0;

          button {
            width: 50px;
            height: 50px;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            border: none;
            transition: 0.3s;
            cursor: pointer;
            -webkit-background-size: contain !important;
            background-size: contain !important;
            outline: none;
            position: relative;

            &:focus {
              box-shadow: 0 0 0 2px rgba(#fff, 0.5);
            }

            &.disabled {
              opacity: 0.5 !important;
              .checked-wrap {
                display: block;
                right: auto;
                left: -4px;
              }
            }

            &.wink {
              background: url('/static/images/svg/icons/smile_w_outline.svg');

              &:hover {
                background: url('/static/images/svg/icons/smile_w_solid.svg');
              }

              &.disabled {
                &:hover {
                  background: url('/static/images/svg/icons/smile_w_outline.svg');
                }
              }
            }

            &.bookmark {
              background: url('/static/images/svg/icons/star_w_outline.svg');

              &:hover {
                background: url('/static/images/svg/icons/star_w_solid.svg');
              }

              &.disabled {
                &:hover {
                  background: url('/static/images/svg/icons/star_w_outline.svg');
                }
              }
            }

            &.chat {
              background: url('/static/images/svg/icons/chat_w_outline.svg');

              &:hover {
                background: url('/static/images/svg/icons/chat_w_solid.svg');
              }

              &.disabled {
                &:hover {
                  background: url('/static/images/svg/icons/chat_w_outline.svg');
                }
              }
            }

            &.mail {
              background: url('/static/images/svg/icons/mail_w_outline.svg');

              &:hover {
                background: url('/static/images/svg/icons/mail_w_solid.svg');
              }

              &.disabled {
                &:hover {
                  background: url('/static/images/svg/icons/mail_w_outline.svg');
                }
              }
            }

            &.gift {
              background: url('/static/images/svg/icons/gift_w_outline.svg');

              &:hover {
                background: url('/static/images/svg/icons/gift_w_solid.svg');
              }

              &.disabled {
                &:hover {
                  background: url('/static/images/svg/icons/gift_w_outline.svg');
                }
              }
            }

            &.favorite {
              background: url('/static/images/svg/icons/heart_w_outline.svg');

              &:hover {
                background: url('/static/images/svg/icons/heart_w_solid.svg');
              }

              &.disabled {
                &:hover {
                  background: url('/static/images/svg/icons/heart_w_outline.svg');
                }
              }
            }
          }
        }
      }

      .user-status {
        position: absolute;
        left: 0;
        bottom: 30px;
        z-index: 3;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        &-wrap {
          background: #ffffff;
          padding: 5px 10px 5px 5px;
          -webkit-border-radius: 0 25px 25px 0;
          -moz-border-radius: 0 25px 25px 0;
          border-radius: 0 25px 25px 0;
          height: 28px;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
        }

        .webcam {
          width: 16px;
          height: 18px;
          display: flex;
          position: relative;
          line-height: 18px;
          color: $black;
          font-weight: 600;

          &.hd {
            &:after {
              content: 'hd';
              text-transform: uppercase;
              display: flex;
              background: $red;
              color: $white;
              font-size: 8px;
              -webkit-border-radius: 10px;
              -moz-border-radius: 10px;
              border-radius: 10px;
              align-items: center;
              justify-content: center;
              width: 20px;
              height: 20px;
              position: absolute;
              top: -10px;
              right: -12px;
              border: 2px solid #ffffff;
            }
          }
        }

        .online {
          color: $black;
          font-size: 12px;
          font-weight: 300;
          line-height: 26px;
          text-transform: uppercase;
          letter-spacing: 1.2px;
          display: flex;
          align-items: center;

          .indication {
            width: 14px;
            height: 14px;
            background-color: $green;
            display: inline-block;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            margin-right: 5px;
          }
        }
      }
    }
  }

  &-name {
    margin-top: 35px;
    margin-left: 15px;

    span {
      color: $black;
      font-size: $basic-font-size;
      font-weight: 400;
      line-height: 26px;
      text-transform: uppercase;
      letter-spacing: 0.8px;
    }

    p {
      color: $black;
      font-size: 26px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 1.3px;
      margin-top: 10px;
    }
  }

  &:hover {
    .user-preview-thumb {
      &:after {
        opacity: 1;
      }

      &:before {
        width: 100%;
      }
    }

    .user-action {
      right: 30px;
      opacity: 1;
    }
  }
}

.user-status-wrap-new {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 5;
  width: 80px;
  height: 28px;
  text-transform: uppercase;
  background: $green;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $white;
  border-radius: 0 0 12px 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 1.2px;
}

@media screen and (max-width: 1720px) {
  .users-preview {
    .user-preview {
      &-thumb {
        &-wrap {
          .user-action {
            position: absolute;
            right: -20px;
            top: 0;
            z-index: 2;
            height: 100%;
            opacity: 0.5;
            transition: 0.3s;
            transition-delay: 0.1s;

            ul {
              button {
                width: 40px;
                height: 40px;
              }
            }
          }
        }
      }

      &:hover {
        .user-preview-thumb {
          &:after {
            opacity: 1;
          }

          &:before {
            width: 100%;
          }
        }

        .user-action {
          right: 20px;
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 1470px) {
  .users-preview {
    .user-preview {
      &-name {
        margin-top: 25px;

        span {
          color: $black;
          font-size: $basic-font-size;
          font-weight: 400;
          line-height: 16px;
          text-transform: uppercase;
          letter-spacing: 0.8px;
        }

        p {
          color: $black;
          font-size: 22px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 1.3px;
          margin-top: 10px;
        }
      }

      &:hover {
        .user-action {
          right: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 1320px) {
  .users-preview {
    .user-preview {
      &-thumb {
        &-wrap {
          .user-action {
            right: -17.5px;

            ul {
              button {
                width: 35px;
                height: 35px;
              }
            }
          }

          .user-status {
            position: absolute;
            left: 0;
            bottom: 30px;
            z-index: 3;
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            &-wrap {
              background: #ffffff;
              padding: 5px 10px 5px 5px;
              -webkit-border-radius: 0 25px 25px 0;
              -moz-border-radius: 0 25px 25px 0;
              border-radius: 0 25px 25px 0;
              height: 20px;
              margin-bottom: 5px;
              display: flex;
              align-items: center;
            }

            .webcam {
              width: 16px;
              height: 18px;
              display: block;
              background-size: 100%;
            }

            .online {
              color: $black;
              font-size: 9px;
              font-weight: 300;
              line-height: 25px;
              text-transform: uppercase;
              letter-spacing: 1.2px;
              display: flex;
              align-items: center;

              .indication {
                width: 10px;
                height: 10px;
                background-color: $green;
                display: inline-block;
                -webkit-border-radius: 100%;
                -moz-border-radius: 100%;
                border-radius: 100%;
                margin-right: 5px;
              }
            }
          }
        }
      }

      &-name {
        span {
          color: $black;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          text-transform: uppercase;
          letter-spacing: 0.8px;
        }

        p {
          color: $black;
          font-size: 22px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 1.3px;
          margin-top: 10px;
        }
      }

      &:hover {
        .user-action {
          right: 15px;
        }
      }
    }
  }

  .user-status-wrap-new {
    width: 50px;
    height: 20px;
    font-size: 11px;
    line-height: 20px;
  }
}

@media screen and (max-width: 1190px) {
  .users-preview {
    .user-preview {
      &-thumb {
        &-wrap {
          .user-action {
            right: -15px;

            ul {
              button {
                width: 30px;
                height: 30px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .users-preview {
    .user-preview {
      &-thumb {
        &-wrap {
          .user-action {
            display: none;
          }
        }
      }

      &-name {
        margin-top: 15px;

        p {
          margin-top: 0;
        }
      }
    }
  }

  .user-status-wrap-new {
    left: 0;
    border-radius: 0 0 12px 0;
    transform: translateX(0);
  }
}

@media screen and (max-width: 991px) {
  .users-preview {
    .user-preview {
      &-thumb {
        &-wrap {
          .user-action {
            display: none;
          }

          .user-status {
            position: absolute;
            left: 0;
            bottom: 0;
            top: 0;
            right: 0;
            z-index: 3;
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            &-wrap {
              background: #ffffff;
              padding: 5px 5px 5px 5px;
              -webkit-border-radius: 0 25px 25px 0;
              -moz-border-radius: 0 25px 25px 0;
              border-radius: 0 25px 25px 0;
              margin-bottom: 5px;
              display: flex;
              align-items: center;

              &.online-wrap {
                position: absolute;
                bottom: 0;
                left: 0;
              }

              &.webcam-wrap {
                position: absolute;
                top: 5px;
                right: 5px;
                -webkit-border-radius: 100%;
                -moz-border-radius: 100%;
                border-radius: 100%;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            .webcam {
              &.hd {
                &:after {
                  content: 'hd';
                  text-transform: uppercase;
                  display: flex;
                  background: $red;
                  color: $white;
                  font-size: 8px;
                  -webkit-border-radius: 10px;
                  -moz-border-radius: 10px;
                  border-radius: 10px;
                  align-items: center;
                  justify-content: center;
                  width: 20px;
                  height: 20px;
                  position: absolute;
                  top: -10px;
                  right: -12px;
                  border: 2px solid #ffffff;
                }
              }
            }

            .online {
              color: $black;
              font-size: 12px;
              font-weight: 300;
              line-height: 26px;
              text-transform: uppercase;
              letter-spacing: 1.2px;
              display: flex;
              align-items: center;

              .indication {
                width: 10px;
                height: 10px;
                background-color: $green;
                display: inline-block;
                -webkit-border-radius: 100%;
                -moz-border-radius: 100%;
                border-radius: 100%;
                margin-right: 0;
              }

              span {
                &:last-child {
                  display: none;
                }
              }
            }
          }
        }
      }

      &-name {
        margin-top: 10px;

        span {
          font-size: 12px;
        }

        p {
          margin-top: 0;
          font-size: 14px;
        }
      }
    }
  }

  .user-status-wrap-new {
    left: 0;
    border-radius: 0 0 12px 0;
    transform: translateX(0);
  }
}
</style>
