<template>
  <transition v-if="modal.open" name="modal">
    <div class="vue-modal user-preview-modal modal-mask">
      <div v-if="modal.loader" class="backdrop-loader">
        <preloader />
      </div>
      <div v-else class="vue-modal--wrapper">
        <div v-click-away="closeModal" class="vue-modal--container modal-dialog">
          <div class="modal-content" :class="{ auth: !modal.preview }">
            <div class="preview-body">
              <div class="two-column">
                <div class="first">
                  <div class="main-image-wrap user-preview-modal-avatar-wrap">
                    <img
                      v-show="!showAvaLoader"
                      ref="main-preview-photo"
                      class="user-preview-modal-avatar"
                      :src="user.watermark"
                      :alt="user.userName + $t('shared.alts.photo')"
                      @load="showAvaLoader = false"
                    />
                    <div v-if="showAvaLoader" class="loader--wrap">
                      <preloader />
                    </div>
                  </div>
                  <ul v-show="modal.preview" class="features list-unstyled user-actions clearfix">
                    <li :class="{ disabled: disabled.chat }" @click="previewStartChatWrap">
                      <span class="rbi-comment-text" />
                    </li>
                    <li :class="{ disabled: disabled.mail }" @click="previewSendMailWrap">
                      <span class="rbi-message" />
                    </li>
                    <li :class="{ disabled: disabled.gift }" @click="previewSendGiftWrap">
                      <span class="rbi-gift" />
                    </li>
                    <li :class="{ disabled: disabled.wink }" @click="previewSendWinkWrap">
                      <span class="checked-wrap">
                        <i class="fa fa-check" />
                      </span>
                      <span class="rbi-emoji" />
                    </li>
                    <li
                      :class="{
                        disabled: disabled.favs.status || disabled.favs.isFav,
                      }"
                      @click="previewAddToFavsWrap"
                    >
                      <span v-if="!disabled.favs.ignored" class="checked-wrap">
                        <i class="fa fa-check" />
                      </span>
                      <span class="rbi-heart" />
                    </li>
                    <li
                      :class="{ disabled: disabled.bookmarks }"
                      @click="previewAddToBookmarksWrap"
                    >
                      <span class="checked-wrap">
                        <i class="fa fa-check" />
                      </span>
                      <span class="rbi-star" />
                    </li>
                  </ul>
                </div>
                <div class="second">
                  <div class="title-wrap">
                    <p
                      class="user-preview-modal__title"
                      v-html="
                        modal.preview
                          ? userName
                          : $t('guest.modals.previewTitle.start') +
                            ' ' +
                            user.userName +
                            $t('guest.modals.previewTitle.end')
                      "
                    />
                    <button type="button" class="close" @click="closeModal">
                      <span class="rbi-close" />
                    </button>
                  </div>
                  <template v-if="modal.preview">
                    <div class="wrap">
                      <div v-show="user.userStatus" class="status-profile">
                        <p
                          class="custom-status"
                          v-text="user.userStatus ? user.userStatus.slice(0, 100) + '...' : ''"
                        />
                      </div>
                    </div>
                    <div class="line">
                      <div class="item">
                        <ul>
                          <li>
                            {{ $t('shared.common.age') }}:
                            <span>{{ user.age }}</span>
                          </li>
                          <li>
                            ID:
                            <span>{{ user.userId }}</span>
                          </li>
                        </ul>
                      </div>
                      <div class="item">
                        <router-link
                          tag="button"
                          :to="{
                            name: 'profile',
                            params: { id: user.userId || 0 },
                          }"
                          type="button"
                          class="btn btn-outline-red"
                          @click.native="closeModal"
                        >
                          {{ $t('shared.links.viewProfile') }}
                        </router-link>
                      </div>
                    </div>
                    <div v-show="user.userDesc" class="text-block">
                      <p class="title-profile">
                        {{ $t('shared.headers.aboutMe') }}
                      </p>
                      <p class="user-description">
                        {{ user.userDesc | limitTest }}
                      </p>
                    </div>
                    <div v-show="user.userPrefer" class="text-block">
                      <p class="title-profile">
                        {{ $t('shared.headers.myPerfectMan') }}
                      </p>
                      <p class="user-prefer">
                        {{ user.userPrefer | limitTest }}
                      </p>
                    </div>
                  </template>
                  <form
                    v-else
                    class="user-preview-modal__form"
                    @submit.prevent="submitRegistration"
                  >
                    <div class="form-group" :class="{ 'has-error': err.first('prRegName') }">
                      <input
                        v-model="regName"
                        type="text"
                        class="form-control"
                        name="prRegName"
                        :placeholder="$t('shared.placeholders.name')"
                      />
                      <div v-show="err.has('prRegName')" class="error">
                        <i class="fa fa-warning" />
                        <span>{{ err.first('prRegName') }}</span>
                      </div>
                    </div>
                    <div class="form-group" :class="{ 'has-error': err.first('prRegEmail') }">
                      <input
                        v-model="regEmail"
                        type="email"
                        class="form-control"
                        name="prRegEmail"
                        :placeholder="$t('shared.placeholders.email')"
                      />
                      <div v-show="err.has('prRegEmail')" class="error">
                        <i class="fa fa-warning" />
                        <span>{{ err.first('prRegEmail') }}</span>
                      </div>
                    </div>
                    <div class="form-group" :class="{ 'has-error': err.first('prRegPassword') }">
                      <input
                        v-model="regPassword"
                        type="password"
                        class="form-control"
                        name="prRegPassword"
                        :placeholder="$t('shared.placeholders.password')"
                      />
                      <div v-show="err.has('prRegPassword')" class="error">
                        <i class="fa fa-warning" />
                        <span>{{ err.first('prRegPassword') }}</span>
                      </div>
                    </div>
                    <div class="form-group" :class="{ 'has-error': err.first('prRegTerms') }">
                      <div class="custom-checkbox">
                        <input
                          id="preview-checkbox"
                          v-model="regTerms"
                          name="prRegTerms"
                          class="inp-cbx"
                          type="checkbox"
                          style="display: none"
                        />
                        <label class="cbx" for="preview-checkbox">
                          <span>
                            <svg width="12px" height="10px" viewBox="0 0 12 10">
                              <polyline points="1.5 6 4.5 9 10.5 1" />
                            </svg>
                          </span>
                          <span>
                            {{ $t('guest.indexHeader.agreement.text') }}
                            <router-link :to="{ name: 'terms' }">{{
                              $t('guest.indexHeader.agreement.link')
                            }}</router-link>
                          </span>
                        </label>
                        <transition name="slide-fade">
                          <div v-show="err.has('prRegTerms')" class="error">
                            <i class="fa fa-warning is-danger" />
                            <span class="help is-danger">{{ err.first('prRegTerms') }}</span>
                          </div>
                        </transition>
                      </div>
                    </div>
                    <div class="form-group clearfix d-flex justify-content-between">
                      <rb-button type="submit" class="btn-outline register-btn" :class="{ bdn: enteringReg }">
                        <span v-if="enteringReg" :class="{ 'wait-spin': enteringReg }" />
                        <span :class="{ wait: enteringReg }">{{
                          $t('guest.indexHeader.btns.register')
                        }}</span>
                      </rb-button>

<!--                      <rb-button class="btn-black" type="button" @click.native="fbLogin">-->
<!--                        <span class="rbi-facebook" />-->
<!--                        {{ $t('guest.indexHeader.btns.facebookLogin') }}-->
<!--                      </rb-button>-->
                    </div>
                  </form>
                </div>
              </div>
              <div v-if="modal.preview" class="thumbnails">
                <thumb-photo
                  v-for="photo in user.images"
                  :key="photo.img_large"
                  :photo="photo"
                  :name="user.userName"
                  @click.native="switchPhoto(photo.wmLarge)"
                />
              </div>
            </div>
            <div v-if="!modal.preview && false" class="user-preview-modal__footer">
              <form
                class="user-preview-modal__footer-login form-inline"
                @submit.prevent="submitLogin"
              >
                <div
                  class="form-group"
                  :class="{
                    'has-error': validErrLog.first('prLogEmail') || logError,
                  }"
                >
                  <input
                    v-model="logEmail"
                    type="email"
                    class="form-control"
                    name="prLogEmail"
                    :placeholder="$t('shared.placeholders.email')"
                  />
                  <div v-show="validErrLog.has('prLogEmail') || logError" class="error">
                    <i class="fa fa-warning" />
                    <span>{{ validErrLog.first('prLogEmail') || logError }}</span>
                  </div>
                </div>
                <div
                  class="form-group"
                  :class="{ 'has-error': validErrLog.first('prLogPassword') }"
                >
                  <input
                    v-model="logPassword"
                    type="password"
                    class="form-control"
                    name="prLogPassword"
                    :placeholder="$t('shared.placeholders.password')"
                  />
                  <span class="btn-reset" @click="toggleResetPassword">
                    <span>?</span>
                  </span>
                  <div v-show="validErrLog.has('prLogPassword')" class="error">
                    <i class="fa fa-warning" />
                    <span>{{ validErrLog.first('prLogPassword') }}</span>
                  </div>
                </div>
                <div>
                  <rb-button class="btn-outline-red" :class="{ bdn: entering }">
                    <span v-if="entering" :class="{ 'wait-spin': entering }" />
                    <span :class="{ wait: entering }">{{ $t('guest.menu.btns.signIn') }}</span>
                  </rb-button>
                </div>
              </form>
              <form
                v-if="showReset"
                v-click-away="closeResetPass"
                class="user-preview-modal__footer-reset"
                @submit.prevent="submitReset"
              >
                <template v-if="!resMessage">
                  <p class="form-group text-center">
                    <b>{{ $t('guest.headers.forgotPassword') }}</b>
                  </p>
                  <div class="form-group" :class="{ 'has-error': validErrRes.first('prResEmail') }">
                    <input
                      v-model="resEmail"
                      type="email"
                      class="form-control"
                      name="prResEmail"
                      :placeholder="$t('shared.placeholders.email')"
                    />
                    <div v-show="validErrRes.has('prResEmail')" class="error">
                      <i class="fa fa-warning" />
                      <span>{{ validErrRes.first('prResEmail') }}</span>
                    </div>
                  </div>
                  <div class="btn-group">
                    <rb-button class="btn-outline-red">
                      {{ $t('guest.menu.btns.sendForgotPass') }}
                    </rb-button>
                  </div>
                </template>
                <template v-else>
                  <div class="text-center text-success">
                    <success />
                    <p>{{ resMessage }}</p>
                    <rb-button class="btn" type="reset" @click.native="toggleResetPassword">
                      {{ $t('guest.modals.btnOK') }}
                    </rb-button>
                  </div>
                </template>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Validator } from 'vee-validate';
import { USER_ACTIONS, ACTIONS_DATA, CHECK_PRIVILEGES } from '../../../mixins/userActions';
import { PHOTO_SRC, AGE, WATERMARK } from '../../../mixins/utils';
import { REGISTRATION, RESET_PASSWORD, LOGIN } from '../../../mixins/formsActions';
import thumbPhoto from '../parts/thumbnailPhoto.vue';
import RbButton from '../../Button';
import Success from '../parts/success';
import Preloader from '../parts/Preloader';

export default {
  name: 'AppPreviewModal',
  regValidator: null,
  loginValidator: null,
  resetValidator: null,
  filters: {
    /**
     * Ограничить кол-во символов и добавить "..", если строка длиннее, чем 100 символов
     *
     * @param val {string}
     */
    limitTest(val) {
      val += '';
      return val.length > 100 ? `${val.slice(0, 100)}...` : val;
    },
  },
  components: {
    thumbPhoto,
    RbButton,
    Success,
    Preloader,
  },
  mixins: [
    USER_ACTIONS,
    REGISTRATION,
    RESET_PASSWORD,
    LOGIN,
    PHOTO_SRC,
    ACTIONS_DATA,
    CHECK_PRIVILEGES,
    AGE,
    WATERMARK,
  ],
  data() {
    return {
      entering: false,
      enteringReg: false,
      err: [],
      showAvaLoader: true,
      showReset: false,
      validErrLog: null,
      validErrRes: null,
      regName: '',
      regEmail: '',
      regPassword: '',
      regTerms: '',
      logEmail: '',
      logPassword: '',
      logError: '',
      resEmail: '',
      resMessage: '',
    };
  },
  computed: {
    actionsUserId() {
      return this.user.userId;
    },
    selfUser() {
      return this.$store.getters.user;
    },
    modal() {
      return this.$store.getters.prevModal;
    },
    loader() {
      return this.modal.loader;
    },
    open() {
      return this.modal.open;
    },
    /**
     *  получить профиль текущего просматриваемого пользователя
     *  с обработкой. Потому что есть разные форматы приходящих данных
     */
    user() {
      const obj = this.modal.user;
      if (this.modal.user.mapped && obj.userAge !== null) {
        this.$set(obj, 'age', this.getUserAge(obj.userAge));
      } else {
        this.$set(obj, 'age', obj.userAge);
      }
      return obj;
    },
    userName() {
      const name = this.user.userName;

      return name ? name.replace(/\b([a-z])([a-z]+)?\b/gim, '<span>$1</span>$2') : '';
    },
  },
  watch: {
    /**
     * Запуск проверки привилегий взаимодействия с пользователем
     *
     * @param val {object} - пользователь
     */
    user(val) {
      val.userId ? this.askPrivileges(val.userId, val.online) : '';
    },
    /**
     * очистка состояния привилегий при закрытии модалки
     *
     * @param val {boolean}
     */
    open(v) {
      if (!v) {
        this.disabled = Object.assign(this.disabled, {
          bookmarks: false,
          favs: {
            status: false,
            isFav: false,
          },
          wink: false,
          chat: false,
        });
      }
    },
    /**
     * установка ватермарки при завершении загрузки данных модалки
     *
     * @param val {boolean}
     */
    loader(v) {
      if (!v && this.open) this.placeWatermark();
    },
    /**
     * Валидация формы регистрации (имя)
     *
     * @param val {string}
     */
    regName(val) {
      this.regValidator.validate('prRegName', val).catch(() => {});
    },
    /**
     * Валидация формы регистрации (email)
     *
     * @param val {string}
     */
    regEmail(val) {
      this.regValidator.validate('prRegEmail', val).catch(() => {});
    },
    /**
     * Валидация формы регистрации (пароль)
     *
     * @param val {string}
     */
    regPassword(val) {
      this.regValidator.validate('prRegPassword', val).catch(() => {});
    },
    /**
     * Валидация формы регистрации (пользовательское соглашение)
     *
     * @param val {string}
     */
    regTerms(val) {
      const tmp = val ? 1 : '';
      this.regValidator.validate('prRegTerms', tmp).catch(() => {});
    },
    /**
     * Валидация формы логина (email)
     *
     * @param val {string}
     */
    logEmail(val) {
      this.loginValidator.validate('prLogEmail', val).catch(() => {});
    },
    /**
     * Валидация формы логина (пароль)
     *
     * @param val {string}
     */
    logPassword(val) {
      this.loginValidator.validate('prLogPassword', val).catch(() => {});
    },
    /**
     * Валидация формы восстановления пароля (email)
     *
     * @param val {string}
     */
    resEmail(val) {
      this.resetValidator.validate('prResEmail', val).catch(() => {});
    },
  },
  mounted() {
    // because we have v-if on forms container

    /**
     * Инициализация плагина вализации для формы с регистрацией
     */
    /// / registration ////
    this.regValidator = new Validator();
    this.regValidator.attach('prRegName', 'required|alpha_spaces', {
      prettyName: this.$t('shared.placeholders.name'),
    });
    this.regValidator.attach('prRegEmail', 'required|email', {
      prettyName: this.$t('shared.placeholders.email'),
    });
    this.regValidator.attach('prRegPassword', 'required|alpha_num|min:8', {
      prettyName: this.$t('shared.placeholders.password'),
    });
    this.regValidator.attach('prRegTerms', 'required', {
      prettyName: this.$t('shared.placeholders.license'),
    });
    this.$set(this, 'err', this.regValidator.errorBag); // update the data.

    this.initLogin();

    /**
     * Инициализация плагина вализации для формы с восстановлением пароля
     */
    /// / reset password ////
    this.resetValidator = new Validator();
    this.resetValidator.attach('prResEmail', 'required|email', {
      prettyName: this.$t('shared.placeholders.email'),
    });
    this.$set(this, 'validErrRes', this.resetValidator.errorBag); // update the data.
  },
  beforeUpdate() {
    /* if ( this.user.images ) {
             let cache = [];
             this.user.images.forEach( img => {
             let tmp = new Image();
             tmp.src = this.setPhotoSource( img.img_large );
             cache.push( tmp );
             } )
             } */
  },
  methods: {
    /**
     * Установить ватермарку на главное фото
     */
    placeWatermark() {
      this.getWatermark({
        targetObj: this.user,
        parentImg: this.user.avatar.img_large,
        wmImg: this.config.watermarkSmall,
        modifierX: 255,
        modifierY: 255,
        variable: 'watermark',
      });
    },
    /**
     * Обработка логина через фейсбук
     */
    fbLogin() {
      this.closeModal();
      /*this.FBLoginStatus()
        .then(() => {
          this.closeModal();
        })
        .catch(() => {
          this.closeModal();
        });*/
    },
    /**
     * Инициализация плагина валидации для формы логина
     */
    initLogin() {
      /// / login ////
      this.loginValidator = new Validator();
      this.loginValidator.attach('prLogEmail', 'required|email', {
        prettyName: this.$t('shared.placeholders.email'),
      });
      this.loginValidator.attach('prLogPassword', 'required', {
        prettyName: this.$t('shared.placeholders.password'),
      });
      this.$set(this, 'validErrLog', this.loginValidator.errorBag); // update the data.
    },
    /**
     * Переключить фотографию
     */
    switchPhoto(url) {
      try {
        if (url) {
          this.showAvaLoader = true;
          this.$refs['main-preview-photo'].src = this.setPhotoSource(url);
        }
      } catch (e) {
        console.error(e);
      }
    },
    /**
     * Закрыть модалку
     */
    closeModal() {
      this.entering = false;
      this.showReset = false;
      this.showAvaLoader = true;
      this.err.clear();
      this.validErrLog.clear();
      this.validErrRes.clear();
      this.$store.commit('editPrevModal', {
        preview: true,
        user: {},
        loader: false,
        open: false,
      });
    },
    /**
     * Переключить отображение формы восстановления пароля
     */
    toggleResetPassword() {
      this.validErrRes.clear();
      this.resMessage = '';
      this.resEmail = '';
      this.showReset = !this.showReset;
    },

    closeResetPass() {
      this.validErrRes.clear();
      this.resMessage = '';
      this.resEmail = '';
      this.showReset = false;
    },
    /**
     * Пред-обработчик кнопки "начать чат"
     */
    previewStartChatWrap() {
      if (this.selfUser.accessToken) {
        this.previewStartChat(true, this.user.userId);
      } else {
        this.$store.commit('editPrevModal', {
          preview: false,
        });
      }
    },
    /**
     * Пред-обработчик кнопки "отправить письмо"
     */
    previewSendMailWrap() {
      if (this.selfUser.accessToken) {
        this.previewSendMail(true, {
          user_id: this.user.userId,
          name: this.user.userName,
          avatar: {
            img_small: this.user.avatar.img_preview,
          },
        });
      } else {
        this.$store.commit('editPrevModal', {
          preview: false,
        });
      }
    },
    /**
     * Пред-обработчик кнопки "отправить подарок"
     */
    previewSendGiftWrap() {
      if (this.selfUser.accessToken) {
        this.previewSendGift(true, this.user.userId);
      } else {
        this.$store.commit('editPrevModal', {
          preview: false,
        });
      }
    },
    /**
     * Пред-обработчик кнопки "добавить в фавориты"
     */
    previewAddToFavsWrap() {
      if (this.selfUser.accessToken) {
        this.previewAddToFavs(true, this.user.userId);
      } else {
        this.$store.commit('editPrevModal', {
          preview: false,
        });
      }
    },
    /**
     * Пред-обработчик кнопки "добавить в закладки"
     */
    previewAddToBookmarksWrap() {
      if (this.selfUser.accessToken) {
        this.previewAddBookmark(true, this.user.userId);
      } else {
        this.$store.commit('editPrevModal', {
          preview: false,
        });
      }
    },
    /**
     * Пред-обработчик кнопки "подмигнуть"
     */
    previewSendWinkWrap() {
      if (this.selfUser.accessToken) {
        this.previewSendWink(true, this.user.userId);
      } else {
        this.$store.commit('editPrevModal', {
          preview: false,
        });
      }
    },
    /**
     * Отправить запрос на регистрацию с предварительной валидацией
     */
    submitRegistration() {
      this.enteringReg = true;
      const tmp = this.regTerms ? 1 : '';
      this.regValidator
        .validateAll({
          prRegName: this.regName,
          prRegEmail: this.regEmail,
          prRegPassword: this.regPassword,
          prRegTerms: tmp,
        })
        .then((success) => {
          if (success) {
            this.mixinRegistration({
              name: this.regName,
              email: this.regEmail,
              password: this.regPassword,
            }).then(() => {
              this.closeModal();
            });
          }
        })
        .catch(() => (this.enteringReg = false));
    },
    /**
     * Отправить запрос на логин с предварительной валидацией
     */
    submitLogin() {
      this.logError = '';
      this.entering = true;
      this.loginValidator
        .validateAll({
          prLogEmail: this.logEmail,
          prLogPassword: this.logPassword,
        })
        .then((success) => {
          if (success) {
            this.mixinLogin({
              email: this.logEmail,
              password: this.logPassword,
            })
              .then(() => this.closeModal())
              .catch((error) => {
                this.logError = error;
              });
          }
        })
        .catch(() => {
          this.entering = false;
        });
    },
    /**
     * Отправить запрос на восстановление пародя с предварительной валидацией
     */
    submitReset() {
      this.resetValidator
        .validateAll({
          prResEmail: this.resEmail,
        })
        .then((success) => {
          if (success) {
            this.mixinResetPasswordForm({
              email: this.resEmail,
            }).then((response) => {
              this.resEmail = '';
              this.resMessage = response;
            });
          }
        })
        .catch(() => '');
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../assets/scss/vars';
.register-btn {
  width: 100%;
}
.user-preview-modal {
  .user-actions {
    li {
      span {
        color: black;
        i {
          color: whitesmoke;
        }
      }
    }
    li:hover {
      span {
        color: #ffffff;
      }
    }
  }
}

.user-preview-modal-avatar-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 456px;
  padding: 3px;
  .user-preview-modal-avatar {
    height: 100%;
  }
}

.btn-outline-red {
  .wait-spin {
    border-color: $red;
  }
}

.backdrop-loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
